import { t } from "translations"

import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"

import ArticleTopic from "Components/ArticleTopic"
import ContentImage from "Components/Image/ContentImage"
import Link from "Components/Link"

export default function TeaserCard({
    teaser,
    index,
    numberOfArticles
}: {
    teaser: IArticleCard
    index: number
    numberOfArticles: number
}) {
    function getHeaderVariant(title: string | undefined) {
        if ((title?.length ?? 0) < 12) return "h1"
        if ((title?.length ?? 0) > 65) return "h3"
        else return "h2"
    }

    const isFirstOfThreeCards = numberOfArticles === 3 && index === 0

    if (!teaser.isContentMarketing)
        return (
            <StyledTeaserCard
                spanfirstcard={isFirstOfThreeCards.toString()}
                href={teaser.url}
                color="inherit">
                <StyledContentImage
                    spanfirstcard={isFirstOfThreeCards.toString()}
                    image={teaser.image}
                    loading={index < 3 ? "eager" : "lazy"}
                />
                <StyledContent spanfirstcard={isFirstOfThreeCards.toString()}>
                    <ArticleTopic topic={teaser.topic} />
                    <StyledHeader
                        variant={getHeaderVariant(teaser.title)}
                        component="h2">
                        {teaser.title}
                    </StyledHeader>
                    <StyledIngress
                        variant="body2"
                        spanfirstcard={isFirstOfThreeCards.toString()}>
                        {teaser.ingress}
                    </StyledIngress>
                </StyledContent>
            </StyledTeaserCard>
        )

    const isProfAd = teaser.isProfessionalContent

    return (
        <StyledCMTeaserCard
            href={teaser.url}
            color="inherit">
            <StyledContentMarketingBanner>
                <Typography
                    variant="overline"
                    color="inherit">
                    {isProfAd
                        ? t.contentMarketing.adProfessional
                        : t.contentMarketing.ad}
                </Typography>
            </StyledContentMarketingBanner>
            <StyledContentImage
                spanfirstcard={isFirstOfThreeCards.toString()}
                image={teaser.image}
                loading={index < 3 ? "eager" : "lazy"}
            />

            <StyledContent spanfirstcard={isFirstOfThreeCards.toString()}>
                <StyledCMTag>
                    <Typography
                        variant="body2"
                        lineHeight={1.2}>
                        {!isProfAd ? (
                            teaser.topic
                        ) : (
                            <>
                                <b>{t.contentMarketing.ad}</b>{" "}
                                {" | " + teaser.topic}{" "}
                            </>
                        )}
                    </Typography>
                </StyledCMTag>
                <StyledHeader
                    variant={getHeaderVariant(teaser.title)}
                    component="h2">
                    {teaser.title}
                </StyledHeader>
                <StyledIngress
                    variant="body2"
                    spanfirstcard={isFirstOfThreeCards.toString()}>
                    {teaser.ingress}
                </StyledIngress>
                <StyledCampaignCode variant="subtitle2">
                    {teaser.approvalCode}
                </StyledCampaignCode>
            </StyledContent>
        </StyledCMTeaserCard>
    )
}

const StyledTeaserCard = styled(Link)<{ spanfirstcard: string }>(
    ({ theme, spanfirstcard }) => ({
        textDecoration: "none",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        ...(spanfirstcard === "true" && {
            [theme.breakpoints.between(712, 1061)]: {
                gridColumnStart: "span 2",
                width: "100%",
                flexDirection: "row"
            }
        }),
        "&:hover": {
            textDecoration: "none"
        }
    })
)

const StyledContentImage = styled(ContentImage)<{ spanfirstcard: string }>(
    ({ spanfirstcard, theme }) => ({
        minHeight: 200,
        maxHeight: 200,
        objectFit: "cover",
        ...(spanfirstcard === "true" && {
            [theme.breakpoints.between(712, 1061)]: {
                width: "50%",
                minHeight: "unset",
                maxHeight: "unset"
            }
        })
    })
)

const StyledContent = styled("div")<{ spanfirstcard: string }>(
    ({ theme, spanfirstcard }) => ({
        position: "relative",
        padding: theme.spacing(0, 2, 2, 2),
        height: "100%",
        ...(spanfirstcard === "true" && {
            [theme.breakpoints.between(712, 1061)]: {
                width: "50%",
                height: "fit-content",
                padding: theme.spacing(2, 2, 2, 2)
            }
        })
    })
)

const StyledHeader = styled(Typography)<{ component: string }>(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    "&:hover": {
        textDecoration: "underline"
    }
}))

const StyledIngress = styled(Typography)<{ spanfirstcard: string }>(
    ({ theme, spanfirstcard }) => ({
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        lineClamp: "2",
        WebkitBoxOrient: "vertical",
        ...(spanfirstcard === "true" && {
            [theme.breakpoints.between(712, 1061)]: {
                WebkitLineClamp: "5",
                lineClamp: "5"
            }
        })
    })
)
const StyledCMTeaserCard = styled(Link)(({ theme }) => ({
    position: "relative",
    width: "100%",
    textDecoration: "none",
    backgroundColor: theme.palette.advertiser[50],
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    border: `${theme.spacing(0.2)} solid ${theme.palette.advertiser.main}`,
    "&:hover": {
        textDecoration: "none"
    }
}))

const StyledContentMarketingBanner = styled("div")(({ theme }) => ({
    position: "absolute",
    color: theme.palette.common.white,
    width: "fill-available",
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.advertiser.main
}))

const StyledCMTag = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
    flexWrap: "wrap"
}))

const StyledCampaignCode = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    bottom: 0
}))
