import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPage } from "data/contentData/interfaces/mongodb/IPage"

import Icon from "Components/Icon/Icon"
import Link from "Components/Link"

export default function ListTemplateList({
    name,
    items
}: {
    name?: string
    items: IPage[]
}) {
    if (!items.length) return null

    return (
        <>
            {name && (
                <Typography
                    variant="h2"
                    sx={{ marginTop: 2 }}>
                    {name}
                </Typography>
            )}

            <StyledList>
                {items.map(child => {
                    const hasChildren = child.children?.length > 0
                    return (
                        <ListItem
                            key={child.contentGuid}
                            disableGutters>
                            <StyledLink href={child.shortcut ?? child.url}>
                                {hasChildren && (
                                    <Icon
                                        sx={{
                                            fontSize: "1rem"
                                        }}
                                        name="arrowForwardIos"
                                    />
                                )}
                                <Typography component="span">
                                    {child.name}
                                </Typography>
                            </StyledLink>
                        </ListItem>
                    )
                })}
            </StyledList>
        </>
    )
}

const StyledLink = styled(Link)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center"
}))

const StyledList = styled(List)(({ theme }) => ({
    width: "100%",
    columnCount: 1,
    [theme.breakpoints.up("md")]: {
        columnCount: 2
    }
}))
