import React from "react"
import { t } from "translations"

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"

import Icon from "Components/Icon/Icon"
import usePagingContext from "Components/Layout/PageComponent/PagingProvider"
import Link from "Components/Link"

export default function ToC({ page }: { page: IPublic }) {
    const { pages } = usePagingContext()

    if (!pages?.length) return null

    return (
        <StyledToC>
            <Typography
                variant="body1"
                fontWeight={700}>
                {t.article.tocTitle}
            </Typography>
            <StyledListWrapper>
                <StyledList>
                    {pages.map((section, index) => (
                        <StyledListItem
                            key={section.url}
                            color="inherit">
                            <StyledLink
                                variant="body2"
                                iscurrent={section.isCurrent ? "true" : "false"}
                                href={section.url.replace("?page=1", "")}
                                id={`chapter-${index + 1}`}>
                                {section.name}
                            </StyledLink>
                        </StyledListItem>
                    ))}
                </StyledList>
            </StyledListWrapper>
            <StyledButtonWrapper>
                <Link
                    href={`${page.url}?page=all`}
                    id="show-text">
                    <Button
                        variant="outlined"
                        endIcon={<Icon name="expandMore" />}>
                        {t.article.showAllText}
                    </Button>
                </Link>
            </StyledButtonWrapper>
        </StyledToC>
    )
}

const StyledToC = styled("div")(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary[100]
}))

const StyledListWrapper = styled("div")(({ theme }) => ({
    paddingLeft: theme.spacing(2)
}))

const StyledList = styled("ol")(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    listStylePosition: "outside",
    fontSize: "1rem",
    paddingInlineStart: 0,
    columnCount: 1,
    [theme.breakpoints.up("md")]: {
        columnCount: 2
    }
}))

const StyledListItem = styled("li")(({ theme }) => ({
    padding: theme.spacing(1),
    maxWidth: 300,
    textOverflow: "ellipsis",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
}))

const StyledLink = styled(Link)<{ iscurrent: string }>(({ iscurrent }) => ({
    fontFamily: "inherit",
    ...(iscurrent === "true" && {
        fontWeight: 700
    }),
    "&:hover": {
        textDecoration: "none"
    }
}))

const StyledButtonWrapper = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end"
}))
