import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { isPublished } from "@nhi/utils"
import { IList } from "data/contentData/interfaces/mongodb/IList"
import { CONTENT_TYPE, getContentType } from "lib/getContentType"

import Head from "Components/Head"

import ListTemplateAside, { ISibling } from "./ListTemplateAside"
import ListTemplateList from "./ListTemplateList"

export default function ListTemplate({ page }: { page: IList }) {
    const theme = useTheme()
    const mdUp = useMediaQuery(theme.breakpoints.up("md"))

    const articles =
        page.children?.filter(
            p =>
                getContentType(p.types) !== CONTENT_TYPE.LIST &&
                isPublished(p.published, p.unpublished)
        ) ?? []

    const lists =
        page.children?.filter(
            p =>
                getContentType(p.types) === CONTENT_TYPE.LIST &&
                isPublished(p.published, p.unpublished)
        ) ?? []

    const siblings: ISibling[] =
        page.siblings
            ?.filter(
                p =>
                    (p.url || p.shortcut) &&
                    getContentType(p.types) === CONTENT_TYPE.LIST &&
                    isPublished(p.published, p.unpublished)
            )
            .map(p => ({
                id: p.contentGuid,
                name: p.name,
                url: p.shortcut ?? p.url,
                current: p.contentGuid === page.contentGuid
            })) ?? []

    return (
        <StyledWrapper>
            <Head page={page} />
            {mdUp && (
                <ListTemplateAside
                    siblings={siblings}
                    page={page}
                />
            )}
            <StyledMain>
                <ListTemplateList
                    name={page.name}
                    items={articles}
                />
                {lists.map(list => (
                    <ListTemplateList
                        key={list.contentGuid}
                        name={list.name}
                        items={list.children ?? []}
                    />
                ))}
            </StyledMain>
        </StyledWrapper>
    )
}

const StyledWrapper = styled("main")(({ theme }) => ({
    maxWidth: "100%",
    overflow: "hidden",
    display: "grid",

    gridTemplateColumns: "1fr",

    [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "1fr 3fr"
    }
}))

const StyledMain = styled("div")(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
}))
