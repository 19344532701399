import { Fragment, useState } from "react"
import { replaceWithComponents, t } from "translations"

import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IProfessional } from "data/contentData/interfaces/mongodb/IProfessional"
import { IPublicVideo } from "data/contentData/interfaces/mongodb/IPublicVideo"

import Icon from "Components/Icon/Icon"
import Link from "Components/Link"

/**
 * Example document: /familie/barn/barn-og-medisin
 */
export function ArticleReferences({ page }: { page: IPublicVideo }) {
    const [open, setOpen] = useState(false)
    const toggleOpen = () => setOpen(!open)

    if (!page) return null

    const references = page.references ?? page.source?.references ?? []
    if (!references || !references.length) return null

    return (
        <StyledReferences>
            <SourceExplanation source={page.source} />
            <StyledAccordion expanded={open}>
                <AccordionSummary
                    color="primary"
                    onClick={toggleOpen}
                    expandIcon={<Icon name="expandMore" />}>
                    <Typography
                        component="span"
                        variant="h6">
                        {t.article.references}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        {references.map(reference => (
                            <Typography
                                variant="body2"
                                component="li"
                                key={reference.referenceId}>
                                {reference.text}
                                {reference.link && (
                                    <Link href={reference.link.href}>
                                        {reference.link.text}
                                    </Link>
                                )}
                            </Typography>
                        ))}
                    </ol>
                </AccordionDetails>
            </StyledAccordion>
        </StyledReferences>
    )
}

function SourceExplanation({ source }: { source?: IProfessional | undefined }) {
    const hasSourceReferences = source && source.references
    if (!hasSourceReferences) return null

    const result = replaceWithComponents(t.article.sourceReferences, {
        source: <Link href={source.url}>{source.name}</Link>
    }).map((i, index) => {
        return {
            key: index,
            content: i
        }
    })

    return (
        <Typography variant="body2">
            {result.map(i => {
                return typeof i.content === "string" ? (
                    <span key={i.key}>{i.content}</span>
                ) : (
                    <Fragment key={i.key}>{i.content}</Fragment>
                )
            })}
        </Typography>
    )
}

const StyledReferences = styled("section")(({ theme }) => ({
    marginTop: theme.spacing(4)
}))

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    marginTop: theme.spacing(2),
    background: theme.palette.grey[200],
    wordBreak: "break-word"
}))
