import React from "react"

import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"

import { IPublicVideo } from "data/contentData/interfaces/mongodb/IPublicVideo"

import Ad from "Components/Ad/Ad"
import ArticleHeader from "Components/Article/ArticleHeader"
import ArticleMeta from "Components/Article/ArticleMeta"
import { ArticleReferences } from "Components/Article/ArticleReferences"
import ArticleVideos from "Components/Article/ArticleVideos"
import LinkedLists from "Components/Article/Widgets/LinkedLists"
import MoreFrom from "Components/Article/Widgets/MoreFrom"
import MostRead from "Components/Article/Widgets/MostRead"
import Related from "Components/Article/Widgets/Related"
import RelatedContentMarketing from "Components/Article/Widgets/RelatedContentMarketing"
import Head from "Components/Head"
import usePagingContext from "Components/Layout/PageComponent/PagingProvider"

import SectionsPaged from "./Paginate/SectionsPaged"
import ToC from "./Paginate/ToC"
import Sections from "./Sections"

export default function DefaultTemplate({ page }: { page: IPublicVideo }) {
    const { pageParam, paginate, paging, showHeader } = usePagingContext()

    const isVideoArticle = page.types.includes("PublicVideo")
    const articleHasTools = page.template?.hasTools ?? false
    const shouldPaginate = paginate && pageParam !== "all"
    const isFirstSection = paging.current === 1

    return (
        <>
            <Head page={page} />
            <AdWrapper>
                <Ad type="toppbanner" />
            </AdWrapper>
            <StyledWrapper
                fullwidth={(isVideoArticle || !articleHasTools).toString()}>
                <StyledArticle>
                    {showHeader && (
                        <>
                            <ArticleHeader page={page} />
                            <ArticleMeta page={page} />
                        </>
                    )}

                    {shouldPaginate && isFirstSection && <ToC page={page} />}
                    {page.videos && <ArticleVideos videos={page.videos} />}

                    <div className="article">
                        {shouldPaginate ? (
                            <SectionsPaged
                                page={page}
                                prev={paging.prev}
                                next={paging.next}
                            />
                        ) : (
                            <Sections sections={page.sections} />
                        )}
                        <LinkedLists linkedLists={page.linkLists} />
                        <ArticleReferences page={page} />
                    </div>
                    {!isVideoArticle && articleHasTools && (
                        <MoreFrom page={page} />
                    )}
                </StyledArticle>
                {!isVideoArticle && articleHasTools && (
                    <ArticleAside>
                        <Ad type="artikkelboard1" />
                        <Related page={page} />
                        <RelatedContentMarketing page={page} />
                        <MostRead />
                        <Ad type="artikkel_skyskraper" />
                    </ArticleAside>
                )}
            </StyledWrapper>
        </>
    )
}

const AdWrapper = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
    }
}))

const StyledWrapper = styled("main")<{ fullwidth: string }>(
    ({ theme, fullwidth = "false" }) => ({
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up("md")]: {
            display: "grid",
            gridTemplateColumns: fullwidth === "true" ? "1fr" : "1fr 300px",
            padding: theme.spacing(4)
        }
    })
)

const StyledArticle = styled("article")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))

const ArticleAside = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))
