import { get } from "@nhi/utils"
import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"

/**
 * Fetch most read pages for a given path
 * @param path the path to fetch most read pages for
 * @param days days to fetch most read pages for
 * @param take take the first x pages
 * @returns a list of most read pages
 */
export async function getMostRead(path = "/", days = 1, take = 3) {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        `/Analytics/MostRead?path=${encodeURIComponent(
            path
        )}&days=${days}&take=${take}`

    return await get<IArticleCard[]>(endpoint)
}
