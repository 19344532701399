import { useEffect, useState } from "react"
import { t } from "translations"

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListSubheader from "@mui/material/ListSubheader"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IVideo } from "data/contentData/interfaces/mongodb/IVideo"

import Video from "Components/Video"

export default function ArticleVideos({ videos }: { videos: IVideo[] }) {
    const [video, setVideo] = useState<IVideo | undefined>(undefined)

    useEffect(() => {
        setVideo(videos && videos.length > 0 ? videos[0] : undefined)
    }, [videos])

    const handleVideoClick = (id: string) => () => {
        const _video = videos.find(p => p.videoId === id)
        setVideo(_video)
    }

    if (!video) return null

    return (
        <>
            <Video
                id={video.videoId}
                service={video.videoService}
            />

            {videos.length > 1 && (
                <StyledList>
                    <StyledListHeader>{t.video.chooseVideo}</StyledListHeader>
                    {videos.map(videoOfVideos => {
                        return (
                            <StyledListItem
                                iscurrent={
                                    videoOfVideos.videoId === video.videoId
                                        ? "true"
                                        : "false"
                                }
                                key={videoOfVideos.contentGuid}
                                onClick={handleVideoClick(
                                    videoOfVideos.videoId
                                )}>
                                <Typography variant="body2">
                                    {videoOfVideos.name}
                                </Typography>
                            </StyledListItem>
                        )
                    })}
                </StyledList>
            )}
        </>
    )
}

const StyledList = styled(List)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxHeight: theme.spacing(34),
    background: theme.palette.common.white,
    padding: 0,
    margin: 0,
    overflow: "auto",
    borderBottom: `1px solid ${theme.palette.grey[300]}`
}))

const StyledListHeader = styled(ListSubheader)(({ theme }) => ({
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText
}))

const StyledListItem = styled(ListItem)<{ iscurrent: string }>(
    ({ iscurrent, theme }) => ({
        backgroundColor:
            iscurrent === "true" ? theme.palette.grey[100] : "none",
        "&:hover": {
            backgroundColor: theme.palette.grey[50],
            cursor: "pointer"
        }
    })
)
