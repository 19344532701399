import React from "react"
import { t } from "translations"

import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { toLocalDate } from "@nhi/utils"
import { ICMPublicVideo } from "data/contentData/interfaces/mongodb/ICMPublicVideo"

import ArticleVideos from "Components/Article/ArticleVideos"
import Head from "Components/Head"
import Icon from "Components/Icon/Icon"
import ContentImage from "Components/Image/ContentImage"
import Link from "Components/Link"
import ContentMarketingAdDisclaimer from "Components/Templates/ContentMarketingTemplate/ContentMarketingAdDisclaimer"
import MoreFromAside from "Components/Templates/ContentMarketingTemplate/ContentMarketingMoreFrom"

import Sections from "../Sections"
import ContentMarketingHeader from "./ContentMarketingHeader"

export default function ContentMarketingTemplate({
    page
}: {
    page: ICMPublicVideo
}) {
    const articleHasTools = page.template?.hasTools ?? false

    const isProfAd = page.professionalContent === true

    return (
        <>
            <Head page={page} />
            {page.logo && (
                <LogoWrapper>
                    <Typography
                        variant="h4"
                        noWrap>
                        {t.contentMarketing.adFrom}{" "}
                    </Typography>
                    <StyledLogo image={page.logo} />
                </LogoWrapper>
            )}

            <ArticleTemplateNameContainer>
                {page.template && (
                    <ArticleTemplateName
                        variant="subtitle2"
                        color="inherit">
                        <Icon
                            name="articleOutlined"
                            fontSize="small"
                        />
                        {page.template.name}
                    </ArticleTemplateName>
                )}

                {isProfAd && (
                    <ArticleTemplateName
                        variant="subtitle2"
                        color="inherit">
                        <Icon
                            name="authourizedPerson"
                            fontSize="small"
                        />
                        {t.userCategory.onlyForHcp}
                    </ArticleTemplateName>
                )}
            </ArticleTemplateNameContainer>
            <StyledWrapper fullwidth={(!articleHasTools).toString()}>
                <StyledArticle>
                    <ContentMarketingHeader page={page} />
                    {page.videos && <ArticleVideos videos={page.videos} />}
                    <Grid
                        item
                        md={8}>
                        {page.byline && (
                            <Typography variant="subtitle2">
                                {page.byline}
                            </Typography>
                        )}
                        {page.published && (
                            <Typography variant="subtitle2">
                                {t.contentMarketing.published}{" "}
                                {toLocalDate(
                                    page.published,
                                    process.env.NEXT_PUBLIC_LOCALE
                                )}
                            </Typography>
                        )}
                    </Grid>

                    <div className="article">
                        <Sections sections={page.sections} />

                        {page.references && (
                            <>
                                <Typography variant="h2">
                                    {t.contentMarketing.references}
                                </Typography>
                                <ol>
                                    {page.references?.map(reference => (
                                        <li key={reference.referenceId}>
                                            {reference.text}
                                            {reference.link && (
                                                <Link
                                                    href={reference.link.href}>
                                                    {reference.link.text}
                                                </Link>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            </>
                        )}
                    </div>
                    <ContentMarketingAdDisclaimer />
                </StyledArticle>
                {articleHasTools && (
                    <ArticleAside>
                        <MoreFromAside page={page} />
                    </ArticleAside>
                )}
            </StyledWrapper>
        </>
    )
}

const LogoWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "28px",
    [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(0),
        marginTop: theme.spacing(0)
    }
}))

const StyledLogo = styled(ContentImage)(() => ({
    width: "auto",
    height: "100%"
}))

const StyledWrapper = styled("main")<{ fullwidth: string }>(
    ({ theme, fullwidth = "false" }) => ({
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,

        [theme.breakpoints.up("md")]: {
            display: "grid",
            gridTemplateColumns: fullwidth === "true" ? "1fr" : "1fr 300px",
            padding: theme.spacing(4)
        }
    })
)

const ArticleTemplateNameContainer = styled("div")(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.advertiser.main,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2, 1, 2),
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2, 0, 2, 4),
        gap: theme.spacing(2)
    }
}))

const ArticleTemplateName = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1)
}))

const StyledArticle = styled("article")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))

const ArticleAside = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))
