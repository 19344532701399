import React from "react"
import { t } from "translations"

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"

import usePagingContext from "Components/Layout/PageComponent/PagingProvider"
import Link from "Components/Link"
import PortableText from "Components/PortableText/PortableText"

import Sections from "../Sections"
import ToC from "./ToC"

export default function PagedSections({
    page,
    prev,
    next
}: {
    page: IPublic
    prev: number
    next: number
}) {
    const { paging, pages } = usePagingContext()

    if (!page) return null

    const currentSection = pages[paging.current - 1]

    const isLastSection = paging.current === pages.length
    const isFirstSection = paging.current === 1
    const getHeaderVariant = isFirstSection ? "h2" : "h1"

    return (
        <>
            {currentSection && (
                <section>
                    {currentSection.leadText && (
                        <PortableText value={currentSection.leadText} />
                    )}
                    {currentSection.name && (
                        <StyledHeader
                            isFirstSection={isFirstSection}
                            variant={getHeaderVariant}>
                            {currentSection.name}
                        </StyledHeader>
                    )}
                    {!isFirstSection && <ToC page={page} />}
                    <PortableText value={currentSection.blockContent} />
                    {currentSection.sections &&
                        currentSection.sections.length > 0 && (
                            <Sections
                                sections={currentSection.sections}
                                level={2}
                            />
                        )}
                </section>
            )}

            <StyledButtonWrapper>
                <StyledLink
                    href={getPreviousUrl(page.url, prev)}
                    disabled={isFirstSection}>
                    <Button
                        variant="contained"
                        disabled={isFirstSection}>
                        {t.article.previousButton}
                    </Button>
                </StyledLink>
                <StyledLink
                    href={`${page.url}?page=${next}`}
                    disabled={isLastSection}>
                    <Button
                        variant="contained"
                        disabled={isLastSection}>
                        {t.article.nextButton}
                    </Button>
                </StyledLink>
            </StyledButtonWrapper>
        </>
    )
}

/**
 * get previous url path
 * @param pageUrl Get previous url path
 * @param previousPage article url
 * @returns return previous url path without page number if page number is 1
 */
function getPreviousUrl(pageUrl: string, previousPage: number) {
    if (previousPage === 1) return pageUrl
    return `${pageUrl}?page=${previousPage}`
}

const StyledButtonWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4)
}))

const StyledLink = styled(Link)(() => ({
    textDecoration: "none",
    "&:hover": {
        textDecoration: "none"
    }
}))

const StyledHeader = styled(Typography)<{ isFirstSection: boolean }>(
    ({ theme, isFirstSection }) => ({
        paddingBottom: isFirstSection ? theme.spacing(0) : theme.spacing(2)
    })
)
