import { t } from "translations"

import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { rewrites } from "lib/routes"

import Icon from "Components/Icon/Icon"
import Image from "Components/Image/Image"
import Link from "Components/Link"

export default function PregnancyOracleFrontpageCard() {
    const ToolItem = ({
        icon,
        title,
        text,
        href
    }: {
        icon: string
        title: string
        text: string
        href: string
    }) => {
        return (
            <StyledLink href={href}>
                <StyledToolsWrapper>
                    <StyledToolsIconsWrapper>
                        <StyledCloudIcon
                            viewBox="0 0 287 183"
                            name="cloudShape"
                        />
                        <StyledToolIcon name={icon} />
                    </StyledToolsIconsWrapper>

                    <StyledToolTitle
                        variant="h5"
                        color="inherit">
                        {title}
                    </StyledToolTitle>
                </StyledToolsWrapper>
                <Divider color="pink" />
                <Typography variant="subtitle2">{text}</Typography>
            </StyledLink>
        )
    }

    return (
        <StyledWrapperCard>
            <StyledLeftSideCard>
                <StyledSmallImage
                    height={80}
                    width={80}
                    alt="foster uke 12"
                    src="/pregnancy/mor.gif"
                />
                <StyledLeftSideContentWrapper>
                    <Image
                        height={240}
                        width={240}
                        alt="mor uke 12"
                        src="/pregnancy/foster.gif"
                    />

                    <Typography variant="h3">
                        {t.pregnancyOracle.frontpageTitle}
                    </Typography>
                    <Typography variant="caption">
                        {t.pregnancyOracle.frontpageDescription}
                    </Typography>
                    <StyledButton
                        href={rewrites["/pregnancyoracle"] + "/foster/0"}
                        endIcon={<Icon name="arrowForward" />}
                        variant="outlined">
                        {t.pregnancyOracle.frontpageButton}
                    </StyledButton>
                </StyledLeftSideContentWrapper>
            </StyledLeftSideCard>
            <StyledRightSideCardWrapper>
                <StyledRightSideCardLink
                    href={rewrites["/pregnancyoracle"] + "/foster/0"}>
                    <StyledRightSideHeaderCard>
                        <StyledHeaderIcon
                            name="pregnancyOracle"
                            viewBox="0 0 212 113"
                        />
                    </StyledRightSideHeaderCard>

                    <StyledRightSideTextCard>
                        <Typography variant="body2">
                            {t.pregnancyOracle.frontpageText}
                        </Typography>
                    </StyledRightSideTextCard>
                </StyledRightSideCardLink>

                <StyledRightToolsCard>
                    <ToolItem
                        icon={"pregnancyOracleClock"}
                        title={t.pregnancyOracle.rantCounter}
                        text={t.pregnancyOracle.rantCounterText}
                        href={rewrites["/pregnancyoracle/contraction-counter"]}
                    />
                    <ToolItem
                        icon={"pregnacyOracleStroller"}
                        title={t.pregnancyOracle.termCalculator}
                        text={t.pregnancyOracle.termCalculatorText}
                        href={rewrites["/pregnancyoracle/duedate"]}
                    />
                    <ToolItem
                        icon={"pregnancOracleCalendar"}
                        title={`${t.pregnancyOracle.mother}: ${t.pregnancyOracle.weekOverview}`}
                        text={t.pregnancyOracle.weekOverviewText}
                        href="/graviditetsoraklet/ukeoversikt-mor"
                    />
                    <ToolItem
                        icon={"pregnancOracleCalendar"}
                        title={
                            t.pregnancyOracle.foetus +
                            ": " +
                            t.pregnancyOracle.weekOverview
                        }
                        text={t.pregnancyOracle.weekOverviewText}
                        href="/graviditetsoraklet/ukeoversikt-foster"
                    />
                </StyledRightToolsCard>
                <StyledButton
                    href={rewrites["/pregnancyoracle"] + "/foster/0"}
                    endIcon={<Icon name="arrowForward" />}
                    variant="outlined">
                    {t.pregnancyOracle.frontpageButton}
                </StyledButton>
            </StyledRightSideCardWrapper>
        </StyledWrapperCard>
    )
}

const StyledWrapperCard = styled("div")(({ theme }) => ({
    display: "flex",
    width: "100%",
    gap: theme.spacing(2),
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
        flexDirection: "row"
    }
}))

const StyledLeftSideCard = styled("div")(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.pregnancy.light,
    border: `1px solid ${theme.palette.pregnancy.dark}`,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
        width: "33%"
    }
}))

const StyledLeftSideContentWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2)
}))

const StyledSmallImage = styled(Image)(({ theme }) => ({
    marginLeft: "auto",
    marginBottom: "-24px",
    borderRadius: "100%",
    border: `3px solid ${theme.palette.pregnancy.dark}`
}))

const StyledRightSideCardWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.pregnancy.dark}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        justifyContent: "space-between",
        maxWidth: "66%",
        "& .MuiButton-outlined": {
            display: "none"
        }
    }
}))
const StyledRightSideHeaderCard = styled("div")({
    display: "flex",
    width: "100%",
    justifyContent: "center"
})

const StyledRightSideCardLink = styled(Link)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    textDecoration: "none",
    "&:hover": {
        textDecoration: "none"
    }
}))

const StyledHeaderIcon = styled(Icon)(({ theme }) => ({
    fontSize: "16rem",
    width: "100%",
    height: "auto",

    [theme.breakpoints.up("md")]: {
        width: "60%"
    }
}))

const StyledCloudIcon = styled(Icon)(({ theme }) => ({
    fill: theme.palette.pregnancy.main,
    width: "3em",
    height: "auto",
    margin: "0 auto",
    paddingBottom: "8px"
}))

const StyledRightSideTextCard = styled("div")(({ theme }) => ({
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
        width: "70%"
    }
}))

const StyledRightToolsCard = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr ",
    gap: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "1fr 1fr 1fr 1fr"
    }
}))

const StyledLink = styled(Link)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    "& .MuiDivider-root": {
        backgroundColor: theme.palette.pregnancy.main
    },
    "&:hover": {
        textDecoration: "none",
        "& .MuiDivider-root": {
            backgroundColor: theme.palette.pregnancy.dark
        },
        "& .MuiSvgIcon-root": {
            fill: theme.palette.pregnancy.dark
        }
    }
}))

const StyledButton = styled(Button)(({ theme }) => ({
    width: "fit-content",
    borderColor: theme.palette.pregnancy.dark,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
        backgroundColor: theme.palette.pregnancy.main,
        borderColor: theme.palette.pregnancy.dark
    },
    "&:focus": {
        backgroundColor: theme.palette.pregnancy.dark,
        borderColor: theme.palette.pregnancy.dark,
        outline: "none"
    }
}))

const StyledToolsIconsWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
})

const StyledToolsWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px"
})

const StyledToolTitle = styled(Typography)({
    display: "flex",
    alignItems: "center"
})

const StyledToolIcon = styled(Icon)({
    marginTop: "-46px"
})
