import { styled } from "@mui/material/styles"

import { isPublished } from "@nhi/utils"
import { IArticlesCard } from "data/contentData/interfaces/mongodb/IArticlesCard"
import { IBlock } from "data/contentData/interfaces/mongodb/IBlock"
import { IContentCard } from "data/contentData/interfaces/mongodb/IContentCard"
import { ITopic } from "data/contentData/interfaces/mongodb/ITopic"
import { CONTENT_TYPE, getContentType } from "lib/getContentType"

import Ad, { AdType } from "Components/Ad/Ad"
import Head from "Components/Head"

import ArticlesCard from "./TopicArticlesCard"
import SubHeadersCard from "./TopicContainerCard/SubHeadersCard"
import ContainerCard from "./TopicContainerCard/TopicContainerCard"
import ContentCard from "./TopicContentCard"

interface ICard {
    key: number
    adType: string
    block?: IBlock
}

export default function TopicTemplate({ page }: { page: ITopic }) {
    if (!page || !page.main) return null

    const cards = insertAds(page.main)

    /**
     * iterate over blocks and then children to transform image properties with eager loading property
     * @param contentCard contentCard to transform with eager loading images
     * @returns transformed contentCard
     */
    function transformBlockcontentWithImageEagerLoading(
        contentCard: IContentCard
    ) {
        for (
            let blockIndex = 0;
            blockIndex < contentCard.blockContent?.length ?? 0;
            blockIndex++
        )
            for (
                let childIndex = 0;
                childIndex <
                    contentCard.blockContent[blockIndex].children?.length ?? 0;
                childIndex++
            ) {
                const element =
                    contentCard.blockContent[blockIndex].children[childIndex]
                if (element._type !== "image") continue

                element.loading = "eager"
            }

        return contentCard
    }

    return (
        <>
            <Head page={page} />

            <StyledWrapper>
                <SubHeadersCard page={page} />

                {cards.map((card, index) => {
                    if (!card.block)
                        return (
                            <Ad
                                key={card.key}
                                type={card.adType as AdType}
                            />
                        )

                    switch (getContentType(card.block.types)) {
                        case CONTENT_TYPE.CARD_CONTENT:
                            return (
                                <ContentCard
                                    key={card.block.contentGuid}
                                    card={
                                        index === 0
                                            ? transformBlockcontentWithImageEagerLoading(
                                                  card.block as IContentCard
                                              )
                                            : (card.block as IContentCard)
                                    }
                                />
                            )

                        case CONTENT_TYPE.CARD_ARTICLES:
                            return (
                                <ArticlesCard
                                    key={card.block.contentGuid}
                                    card={card.block as IArticlesCard}
                                />
                            )

                        case CONTENT_TYPE.CARD_CONTAINER:
                            return (
                                <ContainerCard
                                    key={card.block.contentGuid}
                                    block={card.block}
                                />
                            )

                        default:
                            return (
                                <div key={card.block.contentGuid}>&nbsp;</div>
                            )
                    }
                })}
            </StyledWrapper>
        </>
    )
}

function insertAds(cards: IBlock[]) {
    function getAdType(adNumber: number) {
        if (adNumber === 1) return "toppbanner"

        return `netboard${adNumber - 1}`
    }

    // Insert ad after 2nd card, then every 3rd card
    let adNumber = 1
    const cardsWithAds = cards
        .filter(p => isPublished(p.published, p.unpublished))
        .reduce<ICard[]>((acc, card, index) => {
            acc.push({ key: acc.length, adType: "", block: card })
            if (index === 1 || (index > 1 && (index - 1) % 3 === 0)) {
                acc.push({ key: acc.length, adType: getAdType(adNumber) })
                adNumber++
            }

            return acc
        }, [])

    return cardsWithAds
}

const StyledWrapper = styled("main")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(4)
    }
}))
