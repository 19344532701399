import { GetStaticPropsContext } from "next"
import dynamic from "next/dynamic"
import { ParsedUrlQuery } from "querystring"

import { styled } from "@mui/material/styles"

import { isNonPage, isPublished, toPath } from "@nhi/utils"
import { getFooter, getPage } from "data/contentData/api/content"
import { getMegamenu } from "data/contentData/api/megamenu"
import { getRedirectSingle } from "data/contentData/api/redirect"
import { IMegamenuCategory } from "data/contentData/interfaces/IMegamenuCategory"
import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"
import { ICMPublicVideo } from "data/contentData/interfaces/mongodb/ICMPublicVideo"
import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"
import { IList } from "data/contentData/interfaces/mongodb/IList"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import { IPublicVideo } from "data/contentData/interfaces/mongodb/IPublicVideo"
import { ITopic } from "data/contentData/interfaces/mongodb/ITopic"
import { CONTENT_TYPE, getContentType } from "lib/getContentType"
import useGtmCustomVariables from "lib/hooks/useGtmCustomVariables"
import { rewrites } from "lib/routes"

import { AdContextProvider } from "Components/Ad/AdContext"
import Breadcrumbs from "Components/Breadcrumbs"
import Layout from "Components/Layout/Layout"
import { GalleryProvider } from "Components/Layout/PageComponent/GalleryProvider"
import { PagingProvider } from "Components/Layout/PageComponent/PagingProvider"
import { ReferencesProvider } from "Components/Layout/PageComponent/ReferencesProvider"
import useRefreshScrollRestoration from "Components/Layout/PageComponent/useRefreshScrollRestoration"
import ContentMarketingTemplate from "Components/Templates/ContentMarketingTemplate/ContentMarketingTemplate"
import DefaultTemplate from "Components/Templates/DefaultTemplate"
import ListTemplate from "Components/Templates/ListTemplate/ListTemplate"
import SystemTemplate from "Components/Templates/SystemTemplate"
import TopicTemplate from "Components/Templates/TopicTemplate/TopicTemplate"

const UserCategoryModal = dynamic(
    () => import("Components/UserCategoryModal/UserCategoryModal"),
    {
        ssr: false
    }
)
// const UserCategoryPopover = dynamic(
//     () => import("Components/UserCategoryModal/UserCategoryPopover"),
//     {
//         ssr: false
//     }
// )
const AdSticky = dynamic(() => import("Components/Ad/AdSticky"), {
    ssr: false
})

const Ad = dynamic(() => import("Components/Ad/Ad"), {
    ssr: false
})
const Debug = dynamic(() => import("Components/Debug/Debug"), {
    ssr: false
})

export default function PageComponent({
    page,
    footer,
    megamenu
}: {
    page: IPage
    footer: IContainerCard[]
    megamenu: IMegamenuCategory[]
}) {
    useRefreshScrollRestoration()
    useGtmCustomVariables(page)

    if (!page) return null

    const isProfessionalContent =
        (page as IArticle).professionalContent ?? false

    const PageComponentToRender = getPageComponentToRender(page)

    return (
        <AdContextProvider page={page}>
            <Layout
                footer={footer}
                megamenu={megamenu}>
                <Breadcrumbs page={page} />
                <UserCategoryModal
                    isProfessionalContent={isProfessionalContent}
                />
                <AdSticky
                    alignment="left"
                    type="venstre_skyskraper"
                />
                <AdSticky
                    alignment="right"
                    type="hoyre_skyskraper"
                />
                <GalleryProvider page={page}>
                    <PagingProvider page={page}>
                        <ReferencesProvider page={page}>
                            {PageComponentToRender}
                        </ReferencesProvider>
                    </PagingProvider>
                </GalleryProvider>
                <Debug page={page} />
                <AdWrapper>
                    <Ad
                        type="m_bunnbanner_sticky"
                        format="anchor"
                        collapseEmpty={""}
                    />
                </AdWrapper>
            </Layout>
            {/* <UserCategoryPopover
                isProfessionalContent={isProfessionalContent}
            /> */}
        </AdContextProvider>
    )
}

function getPageComponentToRender(page: IPage) {
    let PageComponentToRender: JSX.Element

    const contentType = getContentType(page.types)
    switch (contentType) {
        case CONTENT_TYPE.START:
        case CONTENT_TYPE.TOPIC:
            PageComponentToRender = <TopicTemplate page={page as ITopic} />
            break
        case CONTENT_TYPE.LIST:
            PageComponentToRender = <ListTemplate page={page as IList} />
            break
        case CONTENT_TYPE.ARTICLE:
            PageComponentToRender = (
                <DefaultTemplate page={page as IPublicVideo} />
            )
            break
        case CONTENT_TYPE.CONTENTMARKETING_ARTICLE:
            PageComponentToRender = (
                <ContentMarketingTemplate page={page as ICMPublicVideo} />
            )
            break
        case CONTENT_TYPE.SYSTEM:
            PageComponentToRender = <SystemTemplate page={page as IPublic} />
            break
        default:
            throw new Error(`Unknown content type: ${page.types.join("-")} `)
    }

    return PageComponentToRender
}

async function getStaticPropsBase(
    params: ParsedUrlQuery | undefined,
    includeProDynamicContent: boolean
) {
    const path = toPath(params?.page)

    const revalidate = Number(process.env.REVALIDATE_STATIC_PROPS)

    if (!path || isNonPage(path)) return { props: {}, revalidate }

    const page = await getPage(path, includeProDynamicContent)

    if (!page) {
        const redirect = await getRedirectSingle(path)
        if (redirect)
            return {
                redirect,
                revalidate
            }

        const shouldRedirect = shouldCheckForRedirect(path)
        if (shouldRedirect) {
            const recheckPage = await getPage(
                shouldRedirect.path,
                includeProDynamicContent
            )
            if (recheckPage)
                return {
                    redirect: {
                        destination: recheckPage.url,
                        permanent: shouldRedirect.permanent
                    },
                    revalidate
                }
        }

        return {
            notFound: true,
            revalidate
        }
    }

    if (!isPublished(page.published, page.unpublished)) {
        const destination =
            getContentType(page.types) === CONTENT_TYPE.CONTENTMARKETING_ARTICLE
                ? rewrites["/cm-article-expired"]
                : rewrites["/article-expired"]
        return {
            redirect: { destination },
            revalidate
        }
    }

    const shouldOverride = pageShouldOverride(page)
    if (shouldOverride)
        return {
            redirect: {
                destination: shouldOverride.url,
                permanent: shouldOverride.permanent
            },
            revalidate
        }

    if (page.shortcut) {
        const destination = page.shortcut.replace(/(.+)\/$/, "$1")
        return {
            redirect: { destination },
            revalidate
        }
    }

    const footer = await getFooter()
    const megamenu = await getMegamenu()

    return {
        props: { page, footer, megamenu },
        revalidate
    }
}

export async function getStaticProps({ params }: GetStaticPropsContext) {
    return getStaticPropsBase(params, false)
}

export async function getStaticPropsPro({ params }: GetStaticPropsContext) {
    return getStaticPropsBase(params, true)
}

/**
 * Check whether page should be redirected
 * @param page page to check
 * @returns object if redirect otherwise null
 */
function pageShouldOverride(page: IPage) {
    const query = page.url.split("/").filter(x => x)

    if (query[0] == "graviditetsoraklet" && (
        query[1] == "mor" || query[1] == "foster"
    ) && query[2].startsWith("svangerskapsuke-"))
        return { url: `${rewrites["/pregnancyoracle"]}/${query[1]}/${query[2].replace("svangerskapsuke-", "")}`, permanent: true }
    return null
}

/**
 * check if path should be redirected
 * @param path path to check
 * @returns path to redirect and whether its permanent or not
 */
function shouldCheckForRedirect(path: string) {
    const isInaccuratePagedPath = cleanPathInaccuratePaging(path)
    if (isInaccuratePagedPath)
        return { path: isInaccuratePagedPath, permanent: true }

    const isInaccurateArchive = checkIfInaccurateArchive(path)
    if (isInaccurateArchive)
        return { path: isInaccurateArchive, permanent: true }

    return null
}

/**
 * check if path ends with /arkiv
 * @param path path to check
 * @returns path without last part
 */
function checkIfInaccurateArchive(path: string) {
    const query = path.split("/")
    const last = query[query.length - 1]
    if (last !== "arkiv") return null

    query.pop()

    const returnValue = query.join("/")
    if (returnValue.startsWith("/")) return returnValue
    return `/${returnValue}`
}

/**
 * clean path of any inaccurate ending paging
 * @param path path of article to clean
 * @returns null if end of path is not a number, otherwise path without the number
 */
function cleanPathInaccuratePaging(path: string) {
    const query = path.split("/")
    const last = query[query.length - 1]
    if (!isNumeric(last)) return null

    query.pop()

    const returnValue = query.join("/")
    if (returnValue.startsWith("/")) return returnValue
    return `/${returnValue}`
}

/**
 * check if string is a number
 * @param str string to check
 * @returns true if string is a number
 */
function isNumeric(str: string) {
    if (typeof str != "string") return false
    return !isNaN(parseFloat(str))
}

const AdWrapper = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
    }
}))
