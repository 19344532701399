import { useRouter } from "next/router"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Button from "@mui/material/Button"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { styled, useTheme } from "@mui/material/styles"

import { IPage } from "data/contentData/interfaces/mongodb/IPage"

import Link from "Components/Link"

export interface ISibling {
    id: string
    name: string
    url: string
    current: boolean
}

export default function ListTemplateAside({
    siblings,
    page
}: {
    siblings: ISibling[]
    page: IPage
}) {
    const router = useRouter()
    const path = router.asPath
    const theme = useTheme()

    return (
        <div>
            <BackButton page={page} />
            <StyledList>
                {siblings.map(sibling => {
                    const current = sibling.url.startsWith(path)

                    return (
                        <StyledListItem
                            key={sibling.id}
                            sx={{
                                backgroundColor: current
                                    ? theme.palette.background.paper
                                    : "none"
                            }}>
                            <StyledLink href={sibling.url}>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    noWrap>
                                    {sibling.name}
                                </Typography>
                            </StyledLink>
                        </StyledListItem>
                    )
                })}
            </StyledList>
        </div>
    )
}

function BackButton({ page }: { page: IPage }) {
    if (!page.parents || page.parents.length === 0) return null

    const parent = page.parents[0]

    return (
        <StyledBackButtonLink href={parent.url}>
            <StyledBackButton
                variant="contained"
                startIcon={<ArrowBackIcon />}>
                {parent.name}
            </StyledBackButton>
        </StyledBackButtonLink>
    )
}

const StyledList = styled(List)(({}) => ({
    backgroundColor: grey[300]
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(1),
    boxSizing: "border-box",
    "&:hover": {
        backgroundColor: theme.palette.action.hover
    }
}))

const StyledLink = styled(Link)(({ theme }) => ({
    flex: "1",
    padding: theme.spacing(0, 1)
}))

const StyledBackButtonLink = styled(Link)(({ theme }) => ({
    "&:hover": {
        color: theme.palette.common.white
    }
}))

const StyledBackButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    color: theme.palette.common.white,
    fontWeight: 600,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "left",
    borderRadius: 0
}))
