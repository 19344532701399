import { t } from "translations"

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"

import CardTitle from "Components/CardTitle"
import Icon from "Components/Icon/Icon"
import Link from "Components/Link"

export default function LinkBoxContainer({
    card: containerCard
}: {
    card: IContainerCard
}) {
    const firstLink = containerCard.cards[0]
    return (
        <StyledWrapper>
            <LinkBoxLight>
                <StyledQuizIcon name="quiz" />
                <CardTitle title={firstLink.title} />
                <LinkBoxIngress
                    variant="body1"
                    color="inherit">
                    {firstLink.ingress}
                </LinkBoxIngress>
                <LightButton
                    variant="outlined"
                    endIcon={<Icon name="arrowForwardCircleOutlined" />}
                    href={firstLink.url}>
                    {t.quiz.start}
                </LightButton>
            </LinkBoxLight>
            <LinkBoxDark>
                <LinkListWrapper>
                    {containerCard.cards.slice(1).map(link => (
                        <LinkListContainer key={link.url}>
                            <StyledHelpIcon name="helpOutlined" />
                            <Link
                                href={link.url}
                                color="inherit">
                                <Typography
                                    variant="body1"
                                    color="inherit">
                                    {link.title}
                                </Typography>
                            </Link>
                        </LinkListContainer>
                    ))}
                </LinkListWrapper>
                <DarkButton
                    variant="contained"
                    href="/quiz"
                    endIcon={<Icon name="arrowForwardCircleOutlined" />}>
                    {t.quiz.seeAll}
                </DarkButton>
            </LinkBoxDark>
        </StyledWrapper>
    )
}

const StyledWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    border: `${theme.spacing(0.25)} solid ${theme.palette.secondary.dark}`,
    [theme.breakpoints.up("md")]: {
        flexDirection: "row"
    }
}))

const LinkBoxLight = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6),
    textAlign: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        flex: 1
    }
}))

const LinkBoxIngress = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}))

const LightButton = styled(Button)(() => ({
    marginTop: "auto"
}))

const StyledQuizIcon = styled(Icon)(({ theme }) => ({
    fontSize: "3rem",
    color: theme.palette.secondary[500],
    "&.MuiSvgIcon-root": {
        width: "100%",
        height: "4em",
        marginBottom: theme.spacing(-4),
        marginTop: theme.spacing(-4)
    }
}))

const LinkBoxDark = styled("div")(({ theme }) => ({
    gap: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    [theme.breakpoints.up("md")]: {
        flex: 1
    }
}))

const DarkButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    marginTop: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.main
    }
}))

const LinkListContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.primary.contrastText
}))

const LinkListWrapper = styled("div")(({ theme }) => ({
    marginTop: "auto",
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))

const StyledHelpIcon = styled(Icon)(({ theme }) => ({
    width: 20,
    height: 20,
    color: theme.palette.secondary.light,
    [theme.breakpoints.up("md")]: {
        width: 30,
        height: 30
    }
}))
